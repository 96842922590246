<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __join-wrap">
        <div class="tit req-type">
          <h1>제휴문의</h1>
          <p class="stit">
            호구153과 제휴를 맺고 서로 WIN-WIN 하여 함께 성장하고자 하는 예비
            파트너사를 모십니다.
          </p>
        </div>
        <div class="sub-cont">
          <h2 class="h2-join">제휴문의</h2>
          <div class="form-wrap">
            <div class="input-form">
              <div class="t-head">성함 <i class="required"></i></div>
              <input
                type="text"
                v-model="name"
                ref="name"
                class="text"
                placeholder="성함을 입력하세요"
                @blur="chkName"
                @keyup.enter="$event.target.blur()"
              />
            </div>
            <div class="input-form">
              <div class="t-head">연락처 <i class="required"></i></div>
              <input
                type="text"
                v-model="tel"
                ref="tel"
                class="text"
                placeholder="연락처를 입력하세요"
                @blur="chkTel"
                @keyup.enter="$event.target.blur()"
              />
            </div>
            <div class="input-form">
              <div class="t-head">이메일주소 <i class="required"></i></div>
              <input
                type="text"
                v-model="email"
                ref="email"
                class="text"
                placeholder="이메일주소를 입력하세요"
                @blur="chkEmail"
                @keyup.enter="$event.target.blur()"
              />
            </div>
            <div class="input-form">
              <div class="t-head">회사(기관)명 <i class="required"></i></div>
              <input
                type="text"
                v-model="company"
                ref="company"
                class="text"
                placeholder="회사(기관)명을 입력하세요"
                @blur="chkCompany"
                @keyup.enter="$event.target.blur()"
              />
            </div>
            <div class="input-form">
              <div class="t-head">부서명 <i class="required"></i></div>
              <input
                type="text"
                v-model="department"
                ref="department"
                class="text"
                placeholder="부서명을 입력하세요"
                @blur="chkDepartment"
                @keyup.enter="$event.target.blur()"
              />
            </div>
            <div class="input-form">
              <div class="t-head">직함</div>
              <input
                type="text"
                v-model="position"
                class="text"
                placeholder="직함을 입력하세요"
              />
            </div>
            <div class="input-form">
              <div class="t-head">제목 <i class="required"></i></div>
              <input
                type="text"
                v-model="title"
                ref="title"
                class="text"
                placeholder="제목을 입력하세요"
                @blur="chkTitle"
                @keyup.enter="$event.target.blur()"
              />
            </div>
            <div class="input-form multi">
              <div class="t-head">업체 소개<i class="required"></i></div>
              <textarea
                name=""
                v-model="companyInfo"
                ref="companyInfo"
                id=""
                cols="30"
                rows="10"
                placeholder="업체 소개를 입력하세요"
                @blur="chkCompanyInfo"
                @keyup.enter="$event.target.blur()"
              ></textarea>
            </div>
            <!--div class="input-form">
              <div class="t-head">파일첨부</div>
              <div class="filebox">
                <input class="upload-name" value="" disabled="disabled" />
                <label for="ex_filename">Upload</label>
                <input type="file" id="ex_filename" class="upload-hidden" />
              </div>
            </!--div-->
          </div>
          <div class="agree_wrap">
            <div class="agree_box">
              1.수집항목(필수): 이름,연락처,이메일주소,회사(기관)명,부서명
              <br />
              2.수집목적: 문의에 따른 원활한 상담 제공 <br />
              3.보유기간: 문의 처리 후 1년간 보관
            </div>
            <p class="check-wrap">
              <input
                type="checkbox"
                v-model="agreeYn"
                id="c1"
                true-value="Y"
                false-value="N"
              />
              <label for="c1">위의 ‘개인정보 수집 및 이용’에 동의합니다.</label>
            </p>
          </div>
        </div>
      </div>
      <div class="btn_wrap">
        <button type="button" class="__mypage" @click="save">문의하기</button>
      </div>
    </main>
  </div>
</template>

<script>
import { postPartnership } from 'Api/modules'
export default {
  data() {
    return {
      name: '',
      tel: '',
      email: '',
      company: '',
      department: '',
      position: '',
      title: '',
      companyInfo: '',
      fileNo: '',
      agreeYn: 'N',
    }
  },
  methods: {
    chkName() {
      if (!this.name) {
        this.$toasted.error('이름을 입력하세요')
        this.$refs['name'].focus()
      }
    },
    chkTel() {
      if (!this.tel) {
        this.$toasted.error('연락처를 입력하세요')
        this.$refs['tel'].focus()
      }
    },
    chkEmail() {
      if (!this.email) {
        this.$toasted.error('이메일주소를 입력하세요')
        this.$refs['email'].focus()
      }
    },
    chkCompany() {
      if (!this.company) {
        this.$toasted.error('회사(기관)명을 입력하세요')
        this.$refs['company'].focus()
      }
    },
    chkDepartment() {
      if (!this.department) {
        this.$toasted.error('부서명을 입력하세요')
        this.$refs['department'].focus()
      }
    },
    chkTitle() {
      if (!this.title) {
        this.$toasted.error('제목을 입력하세요')
        this.$refs['title'].focus()
      }
    },
    chkCompanyInfo() {
      if (!this.companyInfo) {
        this.$toasted.error('업체소개를 입력하세요')
        this.$refs['companyInfo'].focus()
      }
    },
    async save() {
      // saveData
      const saveData = {
        name: this.name,
        tel: this.tel,
        email: this.email,
        company: this.company,
        department: this.department,
        position: this.position,
        title: this.title,
        companyInfo: this.companyInfo,
        fileId: this.fileId,
        agreeYn: this.agreeYn,
      }
      // if (saveData) {
      //   return
      // }
      const res = await postPartnership(saveData)
      if (res) {
        this.$toasted.show('성공적으로 전송되었습니다.')
        this.$router.push('/')
      }
    },
  },
}
</script>

<style></style>
